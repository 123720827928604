<template>
  <div class="scroll-down current">
    <a
      class="btn btn-outline-light btn-lg scroll-down-btn typ-splash-button"
      href="#"
      role="button"
      v-scroll-to="scrollToId"
    >
      {{ $t('message.button.findout') }}
    </a>
    <font-awesome-icon :icon="['fas', 'chevron-down']" size="2x" />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
library.add(faChevronDown)

export default {
  name: 'FindoutButton',
  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    scrollToId: null,
  },
}
</script>

<style lang="scss">
.scroll-down {
  color: #fff;
  z-index: 10;
  position: absolute;
  width: 100%;
  bottom: 5%;
  text-align: center;
  //background-color: lime;
  padding: 0;
  margin: 0;
}

.scroll-down-btn {
  display: block;
  max-width: 240px;
  margin: auto;
}
</style>
