<template>
  <div id="simple-template">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LayoutDfault',
}
</script>

<style lang="scss">
// #simple-template {
//   height: 100%;
// }
</style>
