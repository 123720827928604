import { ApiService, ApiError } from './api.service'

const SlidesService = {
  slides: async function (lang) {
    const resource = '/wordpress/wp-json/wp/v2/media'
    try {
      const response = await ApiService.get(resource, {
        params: {
          context: 'view',
          lang: lang,
          search: 'zijschittert-slide',
          orderby: 'slug',
          order: 'asc',
        },
      })
      return response.data
    } catch (error) {
      throw new ApiError(error.response.status, error.response.data.detail)
    }
  },

  // slides1: async function() {
  //   return {
  //     slides: [
  //       {
  //         imgSrc: "https://picsum.photos/1024/800?image=777",
  //         imgAlt: "image slot",
  //         caption: "Zij Schittert",
  //         text: "Lees de laatste blogs!",
  //         link: "blog"
  //       },
  //       {
  //         imgSrc: "https://picsum.photos/1024/800?image=646",
  //         imgAlt: "image slot",
  //         caption: "Zij Schittert",
  //         text:
  //           "This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.",
  //         link: "about"
  //       },
  //       {
  //         imgSrc: "https://picsum.photos/1024/800?image=778",
  //         imgAlt: "image slot",
  //         caption: "Zij Schittert",
  //         text: "Neem direct contact met mij op.",
  //         link: "contact"
  //       }
  //     ]
  //   };
  // }
}
//https://unsplash.com/s/photos/sparkler

export default SlidesService

export { SlidesService, ApiError }
