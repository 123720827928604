import LayoutSimple from './LayoutSimple.vue'

export default {
  name: 'LayoutSimpleDynamic',
  created() {
    this.$parent.$emit('update:layout', LayoutSimple)
  },
  render() {
    return this.$slots.default[0]
  },
}
