<template>
  <section v-if="errored">
    <p>Sorry er is iets mis gegaan met het laden van deze pagina...</p>
  </section>
  <section v-else>
    <div v-if="loading" class="d-flex flex-column">
      <skeleton-box height="100vh" width="100%" />
    </div>

    <b-carousel
      v-else
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      no-hover-pause
      controls
      fade
      background="#ababab"
    >
      <b-carousel-slide v-for="(slide, index) in slides" :key="index">
        <template v-slot:img>
          <b-img-lazy
            class="cover-center"
            :src="slide.source_url"
            :alt="slide.alt_text"
          />
          <!-- <b-link
            v-if="slide.media_link"
            :to="{ name: slide.media_link }"
            class="jumbotron"
          >
            <logo-picture variant="gold" class="carousel-logo" />
            <span class="typ-subhead" v-html="slide.caption.rendered" />
          </b-link> -->
          <span class="jumbotron">
            <logo-picture variant="gold" class="carousel-logo" />
            <span class="typ-subhead" v-html="slide.caption.rendered" />
          </span>
        </template>
      </b-carousel-slide>
      <findout-button scrollToId="#home-content" />
    </b-carousel>
  </section>
</template>

<script>
import { BCarousel, BCarouselSlide, BImgLazy } from 'bootstrap-vue'
import SkeletonBox from '../components/SkeletonBox'
import FindoutButton from '../components/FindoutButton'
import LogoPicture from '../components/LogoPicture'
import { SlidesService } from '../services/slides.service'
import { mapState } from 'vuex'

export default {
  name: 'Carousel',
  components: {
    SkeletonBox,
    LogoPicture,
    FindoutButton,
    'b-carousel': BCarousel,
    'b-carousel-slide': BCarouselSlide,
    'b-img-lazy': BImgLazy,
  },
  data() {
    return {
      slides: [],
      slide: 0,
      sliding: null,
      loading: true,
      errored: false,
    }
  },
  computed: {
    ...mapState(['lang']),
  },
  watch: {
    lang: function () {
      this.getSlides()
    },
  },
  methods: {
    getSlides() {
      SlidesService.slides(this.lang)
        .then((response) => (this.slides = response))
        .catch(() => {
          this.errored = true
        })
        .finally(() => (this.loading = false))
    },
  },
  mounted() {
    this.getSlides()
  },
}
</script>

<style scoped lang="scss">
.jumbotron {
  position: absolute;
  //top: 60%;
  bottom: 10%;
  //transform: translateY(-50%);
  width: 70%;
  //max-width: 300px;
  margin-right: auto;
  margin-left: auto;
  //top: 30%;
  right: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  color: #fff;
  background-color: unset;

  h1 {
    color: #fff;
  }

  // @include media-breakpoint-down(md) {
  //   top: 10%;
  //   width: 70%;
  //   margin-left: auto;
  //   margin-right: auto;
  // }

  // @include media-breakpoint-down(sm) {
  //   top: auto;
  //   width: 70%;
  //   margin-left: auto;
  //   margin-right: auto;
  // }

  // @include media-breakpoint-down(xs) {
  //   top: 30%;
  //   width: auto;
  // }
}

.carousel-logo {
  display: block;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.cover-center {
  object-fit: cover;
  object-position: center center;
  height: 100vh;
  width: 100vw;
  position: relative;
  bottom: 0px;
}
</style>

<style lang="scss">
@include media-breakpoint-down(xs) {
  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }
}
</style>
