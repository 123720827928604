<template>
  <layout-simple-dynamic>
    <div>
      <carousel />
      <header-fixed />
      <header-sticky />
      <b-container id="home-content">
        <b-row align-h="center" class="header text-center mb-5">
          <b-col class="text-center px-sm-0">
            <header-menu class="mt-5" />
          </b-col>
        </b-row>
      </b-container>
      <page-content :id="id" />
      <footer-fixed />
    </div>
  </layout-simple-dynamic>
</template>

<script>
import '../plugins/scrollto'
import LayoutSimpleDynamic from '../layouts/LayoutSimpleDynamic'
import Carousel from '../components/Carousel'
import HeaderFixed from '../components/HeaderFixed'
import HeaderSticky from '../components/HeaderSticky'
import HeaderMenu from '../components/HeaderMenu'
import PageContent from '../components/PageContent'
import FooterFixed from '../components/FooterFixed'
import { BContainer, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    LayoutSimpleDynamic,
    Carousel,
    HeaderFixed,
    HeaderSticky,
    HeaderMenu,
    PageContent,
    FooterFixed,
    'b-container': BContainer,
    'b-row': BRow,
    'b-col': BCol,
  },
  name: 'home',
  props: {
    id: {
      type: [Number, String],
    },
  },
  metaInfo() {
    return {
      title: `zijschittert - coaching`,
      meta: [
        {
          name: 'description',
          content:
            'Coaching voor vrouwen vanuit bijbels perspectief. - Bible-based coaching for women.',
        },
      ],
    }
  },
}
</script>
